import React from "react";
import {Helmet} from "react-helmet";

import DefaultLayout from './default';
import Logo from '../logo/Logo';

import "./simple.scss";

export default class SimpleLayout extends React.Component {
    render() {
        return (<DefaultLayout>
            <Helmet>
                <title>{ this.props.pageTitle }</title>
            </Helmet>
            <Logo />
            <div className="simple-page__content-holder" dangerouslySetInnerHTML={ {__html: this.props.children} }/>
            </DefaultLayout>);
    }
}